// Vendor JS

// jQuery

@import './src/assets/vendors/jquery/jquery.min.js'

// BootStrap 5

@import './src/assets/vendors/popperjs/popper.min.js'

@import './src/assets/vendors/bootstrap/dist/js/bootstrap.min.js'

// Dashboard Layout

@import './src/assets/vendors/metismenu/js/metisMenu.min.js'

@import './src/assets/vendors/perfect-scrollbar/js/perfect-scrollbar.min.js'

// Form Select Enhancements

@import './src/assets/vendors/select2/select2.full.min.js'

// File Uploads

@import './src/assets/vendors/dropzone/dropzone.min.js'

// WYSIWYG Editor

@import './src/assets/vendors/summernote/summernote-lite.min.js'

// Date Picker

@import './src/assets/vendors/momentjs/moment.js'

@import './src/assets/vendors/tempus-dominus/tempus-dominus.min.js'

// Form Validation

@import './src/assets/vendors/es6-shim/es6-shim.min.js'

@import './src/assets/vendors/formvalidation/js/FormValidation.full.min.js'

@import './src/assets/vendors/formvalidation/js/plugins/Bootstrap5.min.js'

@import './src/assets/vendors/formvalidation/js/plugins/AutoFocus.js'

// Data Tables

@import './src/assets/vendors/datatables/jquery.dataTables.min.js'

// Signatures

@import './src/assets/vendors/signature_pad/signature_pad.umd.min.js'

// Notifications

@import './src/assets/vendors/toastr/toastr.min.js'